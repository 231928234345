import React from 'react';
import {
    RiShareLine,
    RiTerminalBoxLine,
    RiFlightTakeoffFill
} from 'react-icons/ri';
import { FcFlowChart, FcGlobe, FcElectricalSensor } from "react-icons/fc";
import { Link } from 'react-router-dom';
const SIZE = 20;
export default [
    {
        type: 'group',
        label: 'Master',
        level: 0x1fff,
        dashed: true,
        children: [
            {
                key: '/',
                icon: <FcFlowChart size={SIZE} />,
                label: <Link to={'/'}>Routing</Link>,
                level: 0x1fff,
            },
        ]
    },
    {
        type: 'group',
        label: 'Flight',
        level: 0x1fff,
        dashed: true,
        children: [
            {
                key: '/flight_record',
                icon: <FcGlobe size={SIZE} />,
                label: <Link to={'/flight_record'}>Flight Record</Link>,
                level: 0x1fff,
            },
            {
                key: '/fr_logs',
                icon: <FcElectricalSensor size={SIZE} />,
                label: <Link to={'/fr_logs'}>Flight Record Logs</Link>,
                level: 0x1fff,
            }
        ]
    },
]