import React from 'react';
import { List } from 'antd';
import { blue } from '@ant-design/colors';

function ExpandedRowRender({ record }) {
    const { endpoints } = record
    return (
        <div style={{ marginLeft: 30, marginRight: 30 }}>
            <List
                dataSource={endpoints}
                itemLayout="vertical"
                renderItem={item => (
                    <List.Item>
                        <p href={item.url} style={{ color: blue[5] }}>{item.url}</p>
                        <ul>
                            {item.headers.map(header => (
                                <li key={header._id}>
                                    header {header.name} : {header.value}
                                </li>
                            ))}
                        </ul>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default ExpandedRowRender;