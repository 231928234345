import React from 'react';
import {
    BackTop,
    Row,
    Col,
    Card,
    Layout
} from 'antd';
import { IoSearchCircleOutline, IoEarthOutline, IoTvOutline } from "react-icons/io5";

import './index.less';
import { Trans } from '../../components/internationalization/translate';
import Navbar from '../../components/layouts/navbar';
import Footers from '../../components/layouts/footer';

function Shortened() {

    return (
        <Layout className='home'>
            <Navbar skeys="1" />
            <Layout className="App-header">
                <h1 className='title notranslate'>ESI FLIGHT RECORD</h1>
                <p className='normalFont' style={{ width: '40%' }}>Streamer data record flight Shedule</p>
                <Row justify="space-around">
                    <Col xs={24} lg={8} md={12}>
                        <Card className='cardShort' >
                            <span className='cardIcon'><IoEarthOutline /></span>
                            <h3 className='textYellow'><Trans>Live</Trans></h3>
                            <p className='normalFont'><Trans>Live flight shedule tracking data airport</Trans></p>
                        </Card>
                    </Col>
                    <Col xs={24} lg={8} md={12}>
                        <Card className='cardShort' >
                            <span className='cardIcon'><IoSearchCircleOutline /></span>
                            <h3 className='textYellow'><Trans>Search</Trans></h3>
                            <p className='normalFont'><Trans>Search flight record history</Trans></p>
                        </Card>
                    </Col>
                    <Col xs={24} lg={8} md={12}>
                        <Card className='cardShort' >
                            <span className='cardIcon'><IoTvOutline /></span>
                            <h3 className='textYellow'><Trans>Dashboard</Trans></h3>
                            <p className='normalFont'><Trans>management airport create flight record data</Trans></p>
                        </Card>
                    </Col>
                </Row>
                <BackTop />
                <Footers />
            </Layout>
        </Layout>
    );
}

export default Shortened