import React from 'react'
import { useTranslation } from 'react-i18next';

export const Trans = ({ children }) => {
    const { t } = useTranslation();

    return (
        <span>
            {t(children)}
        </span>
    )
}

export const useTrans = (children) => {
    const { t } = useTranslation();

    console.log(children)

    return t(children);
}