import React, { useState } from "react";
import { Form, Input, Row, Col, Button } from "antd";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
const DefModel = {
    airport: ""
};

export default ({ breadcrumb }) => {

    const [model, setModel] = useState(DefModel);

    const layout = {
        labelCol: {
            span: 2,
        }
    };

    return (
        <div>
            <Page breadcrumb={breadcrumb} />
            <br />
            <FormPage
                url={"api/v1/route"}
                callbackPath={"/"}
                formValid={false}
                model={model}
                setModel={setModel}
                title={breadcrumb}
                FormLayout={"horizontal"}
                {...layout}
            >
                <Form.Item
                    name="airport"
                    label="Airport"
                    rules={[{ required: true, message: 'Please input airport code' }]}
                >
                    <Input placeholder="Airport code" size="default" className="input-material" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>
                <Form.List name="endpoints">
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                {fields.map(field => (
                                    <div key={field.key}>
                                        <Form.Item
                                            name={[field.name, 'url']}
                                            label="URL"
                                            rules={[{ required: true, message: 'Please input URL' }]}
                                        >
                                            <Input placeholder="URL" size="default" />
                                        </Form.Item>
                                        <Form.List name={[field.name, 'headers']}>
                                            {(headersFields, { add: addHeader, remove: removeHeader }) => {
                                                return (
                                                    <div>
                                                        {headersFields.map(headerField => (
                                                            <div key={headerField.key}>
                                                                <Form.Item
                                                                    name={[headerField.name, 'name']}
                                                                    label="Header Name"
                                                                    rules={[{ required: true, message: 'Please input header name' }]}
                                                                >
                                                                    <Input placeholder="Header name" size="default" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[headerField.name, 'value']}
                                                                    label="Header Value"
                                                                    rules={[{ required: true, message: 'Please input header value' }]}
                                                                >
                                                                    <Input placeholder="Header value" size="default" />
                                                                </Form.Item>
                                                                <Button
                                                                    type="primary"
                                                                    size="default"
                                                                    danger
                                                                    onClick={() => {
                                                                        removeHeader(headerField.name);
                                                                    }}
                                                                    icon={<CloseCircleOutlined />}
                                                                    style={{ margin: 3, marginLeft: 50 }}
                                                                >
                                                                    Remove header
                                                                </Button>
                                                            </div>
                                                        ))}
                                                        <Button
                                                            type="primary"
                                                            size="default"
                                                            onClick={() => {
                                                                addHeader();
                                                            }}
                                                            icon={<PlusCircleOutlined />}
                                                            style={{ margin: 3, marginLeft: 50 }}
                                                        >
                                                            Add header
                                                        </Button>
                                                    </div>
                                                );
                                            }}
                                        </Form.List>
                                        <Button
                                            type="primary"
                                            size="default"
                                            danger
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                            icon={<CloseCircleOutlined />}
                                            style={{ margin: 3, marginLeft: 50 }}
                                        >
                                            Remove endpoint
                                        </Button>
                                    </div>
                                ))}
                                <Button
                                    type="primary"
                                    size="default"
                                    onClick={() => {
                                        add();
                                    }}
                                    icon={<PlusCircleOutlined />}
                                    style={{ margin: 3, marginLeft: 50 }}
                                >
                                    Add endpoint
                                </Button>
                            </div>
                        );
                    }}
                </Form.List>
            </FormPage>
        </div>
    );
}