let id = {
    translations: {
        "Tool to shorten": "Alat untuk mempersingkat URL atau mengurangi tautan Gunakan Penyingkat URL",
        "Paste here the url you want quickly": "Tempel di sini url yang anda inginkan dengan cepat",
        "Shorten": "Singkat",
        "Here you have full control over your links": "Di sini Anda memiliki kontrol penuh atas tautan Anda",
        "Easy": "Mudah",
        "ShortURL is easy and fast, enter the long link to get your shortened link": "ShortURL mudah dan cepat, masukkan tautan panjang untuk mendapatkan tautan singkat Anda",
        "Shortened": "Menyingkat",
        "Use any link, no matter what size, ShortURL always shortens": "Gunakan tautan apa pun, berapa pun ukurannya, ShortURL selalu lebih pendek",
        "Secure": "Aman",
        "It is fast and secure, our service have HTTPS protocol and data encryption": "Cepat dan aman, layanan kami memiliki protokol HTTPS dan enkripsi data",
        "Statistics": "Statistik",
        "Check the amount of clicks that your shortened url received": "Periksa jumlah klik yang diterima url singkat Anda",
        "Reliable": "Dapat diandalkan",
        "All links that try to disseminate spam, viruses and malware are deleted": "Semua tautan yang mencoba menyebarkan spam, virus, dan malware akan dihapus",
        "Devices": "Perangkat",
        "Compatible with smartphones, tablets and desktop": "Kompatibel dengan smartphone, tablet, dan desktop",
        "Log In": "Masuk",
        "Sign Up": "Daftar",
        "Email": "Email",
        "Password": "Kata Sandi",
        "Full Name": "Nama Lengkap",
        "Created by": "Dibuat Oleh"
    }
};

export default id;
