import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TableAntd';
import Page from '../../../../../components/pages/Page';
import ExpandedRowRender from "./ExpandedRowRender";

export default ({ breadcrumb }) => {

    const columns = [
        {
            title: 'Airport',
            dataIndex: 'airport',
            key: 'airport',
            search: true
        }
    ]

    return (
        <Fragment>
            <Page title='Routing' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master Agent"}
                url="api/v1/route"
                actionPosition={'first'}
                createPath="/master/form/create"
                editPath={"/master/form/edit"}
                columns={columns}
                expandableComponent={{
                    expandedRowRender: record => (
                        <ExpandedRowRender record={record} />
                    ),
                }}
            />
        </Fragment>
    )
}