import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAPI } from './apiHandling';
const Apps = createSlice({
    name: 'apps',
    initialState: { errorMessage: '', loading: false, versionCache: {} },
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        cacheVersion(state, payload) {
            state.versionCache = payload.payload
        },
    }
})

export const { setLoading, unsetLoading, cacheVersion } = Apps.actions
export const loadVersion = createAsyncThunk(
    'apps/load-version', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: `/version` }))
        if (resp?.payload?.data) dispatch(cacheVersion(resp.payload.data))
    }
)

export default Apps.reducer