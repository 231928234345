import React, { useMemo, useState } from 'react'
import { Layout, Menu, Typography } from 'antd';
import './layout.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenu, setOpenKeys } from '../../redux';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri'
import { UserProfile } from './userProfile';
import { Scrollbars } from 'react-custom-scrollbars';
import Logo from '../../assets/link.png';
import { useTheme } from '../themes/use-theme';
import Footers from './footer';

const { Header, Content, Footer, Sider } = Layout;

export const SiderLayout = ({ children, menus, title, theme, headerBgColor, headerColor }) => {
    const { selectedMenu, openKeys } = useSelector(state => state.nav);
    const { userdata } = useSelector(state => state.auth)

    const [darkMode, setDarkMode] = useTheme(false);
    const dispatch = useDispatch();
    const [colapsed, setColapsed] = useState(false);

    const rootSubmenuKeys = useMemo(() => {
        return menus.map(({ key }) => key);
    }, [menus]);
    const renderThumb = ({ style }) => {
        return (<div style={{ ...style, backgroundColor: '#eecccc6e' }} />)
    }

    var result = menus.map(e => ({ ...e, label: '' }));

    return (
        <Layout hasSider>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                style={{ height: '100vh', position: 'fixed', top: 0, bottom: 0, left: 0 }}
                inlineCollapsed={false}
                theme={darkMode ? 'light' : 'dark'}
                collapsed={colapsed}
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    setColapsed(!colapsed)
                }}
            >
                <div className='logo'>
                    {colapsed === false ?
                        <img src={Logo} alt="Logo" width={30} height={30} /> :
                        <img src={Logo} alt="Logo" width={30} height={30} />
                    }
                </div>
                <Scrollbars
                    renderThumbVertical={renderThumb}
                >
                    <Menu
                        size={200}
                        mode="inline"
                        selectedKeys={[selectedMenu]}
                        theme="light"
                        items={colapsed === false ? menus || [] : result || []}
                        onClick={e => {
                            dispatch(setSelectedMenu(e.key));
                        }}
                        openKeys={openKeys}
                        onOpenChange={keys => {
                            const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                                dispatch(setOpenKeys(keys));
                            } else {
                                dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
                            }
                        }}
                    />
                </Scrollbars>
            </Sider>
            <Layout style={{ marginLeft: colapsed ? 0 : 200, minHeight: '100vh' }}>
                <Header style={{ display: 'flex', position: 'fixed', width: '100%', zIndex: 9 }}>
                    <Typography.Title level={6} style={{ fontSize: 15, color: 'white', margin: 'auto', marginLeft: -30 }}>
                        {title || 'FLIGHT RECORD'}
                    </Typography.Title>
                    <UserProfile isColapsed={colapsed} username={userdata?.username} level="1" />
                </Header>
                <Content style={{ display: 'flex', flexDirection: 'column', minHeight: `calc(100% - 80px)`, marginTop: 60 }}>
                    <div style={{ padding: 24, flexGrow: 1 }}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout >
    )
}