import RoutingPage from './master/routing';
import EndpointForm from './master/routing/form';

import Flight_recordPage from './flight/flight_record';
import Flight_record_LogsPage from './flight/fr_logs';

export const Routing = [
    {
        to: '/home',
        level: 0x1f00,
        component: <RoutingPage breadcrumb={['Master', 'Routing']} />
    },
    {
        to: '/master/form/create',
        level: 0x1f00,
        component: <EndpointForm breadcrumb={['Master', 'Create Routing']} />
    },
    {
        to: '/master/form/edit',
        level: 0x1f00,
        component: <EndpointForm breadcrumb={['Master', 'Edit Routing']} />
    },
    {
        to: '/flight_record',
        level: 0x1f00,
        component: <Flight_recordPage breadcrumb={['Flight', 'Flight Record']} />
    },
    {
        to: '/fr_logs',
        level: 0x1f00,
        component: <Flight_record_LogsPage breadcrumb={['Flight', 'Flight Recors Logs']} />
    },
]