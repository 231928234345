import React, { useCallback } from 'react';
import { Card, Button, Layout, Form, Input } from 'antd';
import { useDispatch } from "react-redux";
import Navbar from '../../components/layouts/navbar';
import { Trans, useTrans } from '../../components/internationalization/translate';
import { login } from '../../redux/reducer/auth';

function Login() {
    const dispatch = useDispatch();

    const onLogin = useCallback(async (values) => {
        await dispatch(login({ ...values }));
    }, []);

    return (
        <Layout style={{ height: '100vh' }}>
            <Navbar skeys="2" />
            <Card className='cardLogin'>
                <h1 className='titleLogin notranslate'>Flight Record</h1>
                <Form
                    name="basic"
                    layout="vertical"
                    onFinish={onLogin}
                    autoComplete="off"
                >
                    <Form.Item
                        label={<Trans>Username</Trans>}
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: `Please input your username!`,
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        label={<Trans>Password</Trans>}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: `Please input your ${useTrans('Password')}!`,
                            },
                        ]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block className='btn-yellow' size="large">
                            <Trans>
                                Log In
                            </Trans>
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Layout >
    )
}
export default Login