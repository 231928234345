import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react'
import { RiRefreshLine, RiAddBoxLine, RiEditLine } from 'react-icons/ri'
import { Button, Input, Space, Table } from 'antd';
import { green, grey, yellow, blue } from '@ant-design/colors'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import useLocalStorageState from 'use-local-storage-state';
import { useFetch } from '../../hooks/useFetch';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export default ({ title, columns, actionPosition, url, editPath, createPath, HeaderComponent, otherAction, refresh, conditionalRows,
    ExpandComponent, searchComponent, expandableComponent, scroll
}) => {
    const [qry, setQry] = useState({});
    const history = useHistory();
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [todos, setTodos] = useLocalStorageState("dark-mode", {
        defaultValue: false
    })
    const searchInput = useRef(null);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(false);

    const handleSearch = (selectedKeys) => {
        setSearch(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setSearch('')
    };

    const getColumnSearchProps = (title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[title].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === title ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const modifiedColumns = columns.map((column) => {
        if (column.search) {
            return {
                ...column,
                ...getColumnSearchProps(column.title),
            };
        }
        return column;
    });

    if (!!editPath) {
        modifiedColumns.push({
            key: 'edit',
            title: 'Edit',
            dataIndex: 'Edit',
            render: (_, rows) => (
                <>
                    <Button
                        icon={<RiEditLine size={15} color={green[7]} />}
                        type="text"
                        onClick={() => history.push({ pathname: editPath, state: { ...rows, isCreate: false } })}
                    />
                </>
            ),
        });
    }

    const [localRefresh, setLocalRefresh] = useState(moment().unix())
    const uri = useMemo(() => {
        const parentRefresh = parseInt(refresh) || 0;
        const r = localRefresh > parentRefresh ? localRefresh : parentRefresh;
        return `${url}?page=${page}&perPage=${perPage}&search=${search}&search2=${JSON.stringify(qry)}&timestamp=${r}`;
    }, [url, refresh, localRefresh, qry, search, page, perPage])
    const [data, loading] = useFetch(uri);

    const handlePaginationChange = (pagination) => {
        setPage(pagination)
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBlock: 10 }}>
                <Button
                    icon={<RiRefreshLine size={15} color={yellow[7]} />}
                    style={{ margin: 2 }}
                    title='Refresh'
                    onClick={() => setLocalRefresh(moment().unix())}
                />
                {
                    !!createPath && <Button
                        icon={<RiAddBoxLine size={15} color={blue[7]} />}
                        title='Add'
                        style={{ margin: 2 }}
                        onClick={() => history.push({ pathname: createPath, state: { isCreate: true } })}
                    />
                }
            </div>
            <Table
                columns={modifiedColumns}
                expandable={expandableComponent || null}
                expandedRowRender={ExpandComponent}
                className='small-table'
                dataSource={data?.data || []} 
                size="small"
                pagination={{
                    total: `${data?.total || 0}`,
                    pageSize: perPage,
                    onChange: handlePaginationChange,
                    position: ['topRight', "bottomRight"]
                }}
                rowKey="_id"
                loading={loading}
                scroll={scroll}
            />
        </Fragment>
    )
}