import {
    Layout,
    Menu,
    Col,
    Affix,
    Row
} from 'antd';
import './layout.css';
import logoalibali from '../../assets/link.png';
import { Link } from "react-router-dom";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../themes/use-theme';
import { useTrans } from '../internationalization/use-trans';
import { Trans } from '../internationalization/translate';

const { Header } = Layout;

function Navbar({ skeys }) {
    const { i18n } = useTranslation();
    const [darkMode, setDarkMode] = useTheme(false);
    const [transMode, setTransMode] = useTrans(false);
    const usei18nextLng = localStorage.getItem('i18nextLng');

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    const onChange = (checked) => {
        i18n.changeLanguage(usei18nextLng === "en" ? "id" : "en");
        setTransMode(checked);
    };

    return (
        <Affix offsetTop={0}>
            <Header className="header" >
                <Row>
                    <Col span={6}>
                        <img src={logoalibali} width="40" />
                    </Col>
                    <Col span={7} offset={8}>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={[skeys]} >
                            <Menu.Item key="1">
                                <Link to="/">
                                    <Trans>Home</Trans>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to="/dashboard">
                                    <Trans>Log In</Trans>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col span={1}>
                        <Row>
                            <DarkModeSwitch
                                checked={darkMode}
                                onChange={toggleDarkMode}
                                size={25}
                                moonColor="#ffb743"
                                sunColor="white"
                                style={{ marginTop: 20 }}
                            />
                        </Row>
                    </Col>
                </Row>
            </Header>
        </Affix>
    )
}

export default Navbar