import React, { Fragment, useState } from 'react';
import TablePage from '../../../../../components/pages/TableAntd';
import Page from '../../../../../components/pages/Page';
import moment from 'moment';
import { RiRefreshFill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { GetAPI } from '../../../../../redux';
import ExpandedRowRender from './ExpandedRowRender';
import { blue } from '@ant-design/colors';

export default ({ breadcrumb }) => {

    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(moment().unix());
    const { apps } = useSelector(state => state.auth);

    const onSync = async (url) => {
        return dispatch(GetAPI({ url, apps })).then(resp => {
            setRefresh(moment().unix());
        })
    }

    const columns = [
        {
            title: 'Resend',
            dataIndex: '_id',
            key: '_id',
            fixed: 'left',
            width: 70,
            search: false,
            render: (_, { _id }) => (
                <>
                    <RiRefreshFill style={{ cursor: 'pointer', color: blue[4] }} onClick={() => onSync(`api/v1/fr_integrator/resend/${_id}`)} />
                </>
            ),
        },
        {
            title: 'Flight Number',
            dataIndex: 'flight_number',
            fixed: 'left',
            key: 'flight_number',
            search: true,
            width: 140,
            render: (_, { flight_number}) => (
                <div style={{display:'flex', flexDirection:'column', }}>
                    <span>{flight_number === '' ? '-' : flight_number}</span>
                </div>
            ),
        },
        {
            title: 'Airline',
            dataIndex: 'airline',
            key: 'airline',
            width: 200,
            search: false,
            render: (_, { airline,  flight_type, flight_type_code  }) => (
                <Fragment>
                    <span style={{display:'block'}}>{airline?.name === '' ? '-' : airline?.name.substring(0, 17)} ({flight_type_code})</span>
                    <span style={{display:'block'}}>{`${flight_type}`.substring(0, 18)}</span>
                </Fragment>
            ),
        },
        
        {
            title: 'AcReg',
            dataIndex: 'aircraft_registration',
            key: 'aircraft_registration',
            width: 120,
            search: true,
            render: (_, { aircraft_registration }) => (
                <span>{aircraft_registration === '' ? '-' : aircraft_registration}</span>
            ),
        },
        {
            title: 'AcType',
            dataIndex: 'aircraft_type',
            key: 'aircraft_type',
            search: false,
            render: (_, { aircraft_type }) => (
                <span>{aircraft_type === '' ? '-' : aircraft_type}</span>
            ),
        },
        {
            title: 'Flight Date',
            dataIndex: 'flight_date',
            key: 'flight_date',
            width: 120,
            search: false
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            search: false,
            width: 160,
            render: (_, { status }) => (
                <span>{status === undefined ? '-' : status}</span>
            ),
        },
        {
            title: 'Origin',
            dataIndex: 'origin',
            key: 'origin',
            width: 80,
            search: true,
            render: (val, { org }) => (
                <span>{val}</span>
            ),
        },
        {
            title: 'Dest',
            dataIndex: 'dest',
            width: 80,
            key: 'dest',
            search: true,
            render: (val, { dst }) => (
                <span>{val}</span>
            ),
        },
        {
            title: 'Terminal',
            dataIndex: 'flight_type',
            key: 'flight_type',
            search: false,
            render: (_, { departureTerminal, arrivalTerminal }) => (
                `${departureTerminal||'-'} : ${arrivalTerminal||'-'}`
            ),
        },
        {
            title: 'Gate',
            dataIndex: 'flight_type',
            key: 'flight_type',
            search: false,
            render: (_, { departureGate, arrivalGate }) => (
                `${departureGate||'-'} : ${arrivalGate||'-'}`
            ),
        },
        {
            title: 'STD',
            dataIndex: 'unixStd',
            key: 'unixStd',
            search: false,
            render: (_, { unixStd, org }) => (
                <span>{unixStd === 0 ? '-' : moment.unix(unixStd + (org?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'STA',
            dataIndex: 'unixSta',
            key: 'unixSta',
            search: false,
            render: (_, { unixSta, dst }) => (
                <span>{unixSta === 0 ? '-' : moment.unix(unixSta + (dst?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'ATD',
            dataIndex: 'unixAtd',
            key: 'unixAtd',
            search: false,
            render: (_, { unixAtd, org }) => (
                <span>{unixAtd === 0 ? '-' : moment.unix(unixAtd + (org?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'ATA',
            dataIndex: 'unixAta',
            key: 'unixAta',
            search: false,
            render: (_, { unixAta, dst }) => (
                <span>{unixAta === 0 ? '-' : moment.unix(unixAta + (dst?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'ETD',
            dataIndex: 'unixEtd',
            key: 'unixEtd',
            search: false,
            render: (_, { unixEtd, org }) => (
                <span>{unixEtd === 0 ? '-' : moment.unix(unixEtd + (org?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'ETA',
            dataIndex: 'unixEta',
            key: 'unixEta',
            search: false,
            render: (_, { unixEta, dst }) => (
                <span>{unixEta === 0 ? '-' : moment.unix(unixEta + (dst?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'Block Off',
            dataIndex: 'unixBlockOff',
            key: 'unixBlockOff',
            search: false,
            render: (_, { unixBlockoff, org }) => (
                <span>{unixBlockoff === 0 ? '-' : moment.unix(unixBlockoff + (org?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'Block On',
            dataIndex: 'unixBlockOn',
            key: 'unixBlockOn',
            search: false,
            render: (_, { unixBlockon, dst }) => (
                <span>{unixBlockon === 0 ? '-' : moment.unix(unixBlockon + (dst?.timezone_offset || 0)).utc().format('HH:mm')}</span>
            ),
        },
        {
            title: 'Last Update',
            dataIndex: 'last_update',
            key: 'last_update',
            search: false,
            width: 180,
            render: (_, { last_update }) => (
                <span>{moment.unix(last_update).format('DD MMM YYYY HH:mm:ss')}</span>
            ),
        },
    ]

    return (
        <Fragment>
            <Page title='Flight Record' breadcrumb={breadcrumb} />
            <TablePage
                title={"History Flight Record"}
                url="api/v1/fr_integrator"
                actionPosition={'first'}
                columns={columns}
                expandableComponent={{
                    expandedRowRender: record => (
                        <ExpandedRowRender id={record._id} />
                    ),
                }}
                refresh={refresh}
                scroll={{
                    x: 1800
                }}
            />
        </Fragment>
    )
}