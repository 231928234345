import Page from '../../../../../components/pages/Page';
import moment from 'moment';
import React from 'react';
import { useFetchLogs } from './fectLogs';

export default ({ breadcrumb }) => {

    const [frLogs, logsEndRef] = useFetchLogs("api/v1/fr_integrator/fr_logs")
    const regex = /Success/i;

    return (
        <Page title='Flight Record Logs' breadcrumb={breadcrumb}>
            {!!frLogs && frLogs.map((log, index) => (
                <div>
                    {regex.test(log?.result) ?
                        <div key={index}>{moment(log.createdAt).format('DD MMM YYYY HH:mm:ss')} | {log?.url} | {log?.result}</div> :
                        <div key={index} style={{ color: 'red', fontWeight: 'bold' }}>{moment(log.createdAt).format('DD MMM YYYY HH:mm:ss')} | {log?.url} | {log?.result}</div>
                    }
                </div>
            ))}
            <div ref={logsEndRef} style={{ paddingTop: 10 }} />
        </Page>
    )
}
