import auth from './reducer/auth';
import nav from './reducer/navigations';
import apps from './reducer/apps';

const rootReducer = {
    auth,
    nav,
    apps
}

export default rootReducer
