import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAPI } from '../../../../../redux';
import useInterval from "use-interval";
import useSound from 'use-sound';
import fanfareSfx from '../../../../../assets/message.mp3';

export const useFetchLogs = (url) => {
    const dispatch = useDispatch();
    const { apps } = useSelector((state) => state.auth);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const logsEndRef = useRef(null);

    const [play, { stop }] = useSound(fanfareSfx);

    useInterval(() => {
        setLoading(true);
        logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        dispatch(GetAPI({ url, apps })).then((resp) => {
            const { payload } = resp;
            setData(payload.data);

            for (let index = 0; index < payload.data.length; index++) {
                const element = payload.data[index];
                const regex = /Success/i;

                if (regex.test(element?.result)) {
                } else {
                    play()
                }
            }
            setLoading(false);
        });
    }, 2000);

    return [data, logsEndRef, loading];
};
