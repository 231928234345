import moment from 'moment';
import { useFetch } from '../../../../../hooks/useFetch';

function ExpandedRowRender({ id }) {

    const [dataLog] = useFetch(`api/v1/fr_integrator/logs_fr/${id}`);

    return (
        <div style={{ marginLeft: 30, marginRight: 30, maxHeight: 200, overflow: "auto" }}>
            <div style={{ display: 'flex' }}>
                <table style={{ border: '1px solid #e0e0e0', borderCollapse: 'collapse', width: "50%", marginBottom: '10px', marginTop: '10px', fontSize: 11 }}>
                    <thead style={{ backgroundColor: '#009eff', color: 'white' }}>
                        <tr>
                            <th style={{ border: '1px solid #62B6B7', padding: '1px' }}>Success Sync</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!dataLog?.success && dataLog?.success.map((row, idx) => (
                                <tr key={`${idx}__${row._id}`}>
                                    <td style={{ border: '1px solid #e0e0e0', padding: '1px' }}>{moment(row.createdAt).format('DD MMM YYYY HH:mm:ss')} | {row.url} | {row.result}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <table style={{ border: '1px solid #e0e0e0', borderCollapse: 'collapse', width: "50%", marginBottom: '10px', marginTop: '10px', fontSize: 11 }}>
                    <thead style={{ backgroundColor: '#EB455F', color: 'white' }}>
                        <tr>
                            <th style={{ border: '1px solid #EB455F', padding: '1px' }}>Error Sync</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!dataLog?.error && dataLog?.error.map((row, idx) => (
                                <tr key={`${idx}__${row._id}`}>
                                    <td style={{ border: '1px solid #e0e0e0', padding: '1px' }}>{moment(row.createdAt).format('DD MMM YYYY HH:mm:ss')} | {row.url} | {row.result}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ExpandedRowRender;